import ChangelogItem, { ChangelogItemType } from "components/changelog-item"
import * as DesignSystem from "components/design-system"
import Holder from "components/holder"
import Section from "components/section"
import { graphql } from "gatsby"
import * as React from "react"
import Layout from "templates/layout"

interface Edge {
  node: {
    frontmatter: {
      path: string
      title: string
      type: ChangelogItemType
      date: string
    }
    body: string
  }
}

interface ChangelogProps {
  data: {
    allMdx: {
      edges: Edge[]
    }
  }
}

export default class Changelog extends React.Component<ChangelogProps> {
  render() {
    const { edges } = this.props.data.allMdx

    return (
      <Layout alwaysOnHeader={true} title="Changelog | Stackchat">
        <Section padding="large" topPaddingOnly={true}>
          <Holder width={12} className="text-center">
            <DesignSystem.H2>Stackchat Studio Changelog</DesignSystem.H2>
          </Holder>
        </Section>

        <Section padding="regular">
          <Holder width={12} className="d-flex flex-column">
            {
              edges.map((edge, index) => {
                const { date, title, type, path } = edge.node.frontmatter
                return (
                  <ChangelogItem
                    key={index}
                    date={date}
                    title={title}
                    type={type}
                    link={path}
                  />
                )
              })
            }
          </Holder>
        </Section>
      </Layout>
    )
  }
}

export const query = graphql`
{
  allMdx(
    sort: {fields: [frontmatter___date], order: DESC}, 
    filter: {frontmatter: {path: {regex: "/^\\/changelog/i"}}}
  ) {
    edges {
      node {
        frontmatter {
          path
          title
          type
          date(formatString: "DD MMM YYYY")
        }
      }
    }
  }
}
`
